const prefix = '/r2/'

export function storeImage(filename, options) {
  return fetch(prefix + filename, {
    ...options,
    headers: {
      ...options?.headers,
    }
  }).then(response => response.json())
    .catch(err => {
      const message = err.message || err
      alert(message)
      return message
    })
}