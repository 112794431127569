import { AUTH_KEY } from '../common/constant'
const prefix = '/api/blog'

export function request(url, options) {
  return fetch(prefix + url, {
    ...options,
    headers: {
      ...options?.headers,
      [AUTH_KEY]: (sessionStorage.getItem(AUTH_KEY) || '') && 'Bearer ' + sessionStorage.getItem(AUTH_KEY)
    }
  }).then(response => {
    if (response.status === 401) return Promise.reject('Unauthorized')
    if (response.status !== 200) return Promise.reject(response.statusText)
    return response.json()
  }).catch(err => {
    const message = err.message || err
    alert(message)
    return message
  })
}

export function login(username, password) {
  return request('/user/login', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password
    })
  }).then(data => {
    sessionStorage.setItem(AUTH_KEY, data.token)
    return data
  })
}

export function getArticleList() {
  return request('/article')
}

export function getArticleDetail(id) {
  return request(`/article/${id}`)
}

export function updateArticle(id, title, content) {
  return request(`/article/${id}`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      content,
    })
  })
}

export function addArticle(title, content) {
  return request('/article', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      content,
    })
  })
}
