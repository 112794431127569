import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BlogCard from '../views/BlogCard'
import avatar from '../assets/avatar.png'
import dish from '../assets/food.jpg'
import { getArticleList } from '../api/blog'


function Home() {
  const [articles, setArticles] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    getArticleList()
      .then(res => {
        console.log({ res })
        if (res === 'Unauthorized') {
          navigate('/login')
          return []
        }
        return res
      })
      .then(json => setArticles(json))
  }, []);

  return (
    <div className="App">
      <div style={{ margin: "20px auto" }} className="row container">
        <BlogCard
          avatar={avatar}
          id={''}
          title={'Create New Article'}
          logo={dish}
        />
        {articles.map((article) => (
          <BlogCard
            avatar={avatar}
            id={article.id}
            title={article.title}
            logo={article.logo || dish}
            rating={article.rating}
            key={article.id}
          />
        ))}
      </div>
      <div className="container-fluid">
      </div>
    </div>
  )
}

export default Home