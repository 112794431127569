
import React, { useState, useEffect } from 'react'
import { Container, Paper, TextField, Button, Typography } from '@mui/material'
import { AccountCircle, Lock } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { login } from '../api/blog'

const Login = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')


  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleLogin = () => {
    login(username, password)
      .then(() => {
        return navigate('/')
      })
  }

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
          Login
        </Typography>
        <TextField
          fullWidth
          label="Username"
          variant="outlined"
          margin="normal"
          value={username}
          onChange={handleUsernameChange}
          InputProps={{
            startAdornment: <AccountCircle />,
          }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            startAdornment: <Lock />,
          }}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleLogin}
          sx={{ marginTop: 2 }}
        >
          Login
        </Button>
      </Paper>
    </Container>
  )
}

export default Login
