import React, { useState, useRef, useEffect } from 'react'
import { Container, Paper, Button, Typography, TextField } from '@mui/material'
import debounce from 'lodash/debounce'
import MarkdownPreview from '../components/markdown/Preview'
import { useNavigate, useParams } from 'react-router-dom'
import { updateArticle, addArticle, getArticleDetail } from '../api/blog'
import { storeImage } from '../api/r2'

function image2Base64(img, type) {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  const dataURL = canvas.toDataURL(type);
  return dataURL;
}

const useMyDebounce = (fun, wait, options) => {
  const myRef = useRef()
  if (!myRef.current) {
    myRef.current = debounce(fun, wait, options)
  }
  return myRef.current
}


const Editor = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const [title, setTitle] = useState('')
  const [markdownText, setMarkdownText] = useState('')
  const [renderedMarkdown, setRenderedMarkdown] = useState('')
  const forUpdate = !!routeParams.id


  useEffect(() => {
    if (forUpdate)
      getArticleDetail(routeParams.id)
        .then(json => {
          setTitle(json.title)
          setMarkdownText(json.content)
        })
  }, [forUpdate, routeParams.id])

  const handleMarkdownChange = (event) => {
    setMarkdownText(event.target.value)
  }
  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const handleImageUpload = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const img = new Image()
        img.src = e.target.result
        img.onload = () => {
          const base64image = image2Base64(img, file.type)
          const imgCode = base64image.replace(`data:${file.type};base64,`, "");

          storeImage(file.name, {
            method: 'PUT',
            body: imgCode,
          })
            .then((data) => {
              const newMarkdownText = `${markdownText}![Dropped Image](${data.file})\n`
              setMarkdownText(newMarkdownText)
            })
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDropImage = (event) => {
    handleImageUpload(event)
  }
  const delayedRender = useMyDebounce((text) => {
    setRenderedMarkdown(text)
  }, 500)

  const previewMarkdown = () => {
    delayedRender(markdownText)
  }

  const save = () => {
    console.log(title, markdownText)
    if (forUpdate) {
      updateArticle(routeParams.id, title, markdownText)
        .then(() => navigate('/'))
    } else {
      addArticle(title, markdownText)
        .then(() => navigate('/'))
    }
  }

  return (
    <div>
      <Container maxWidth='100vw' sx={{ display: 'flex', justifyContent: 'space-between', height: '100vh' }}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleDropImage}
      >
        <Paper elevation={3} sx={{ flex: 1, padding: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Markdown Editor
          </Typography>
          <TextField
            fullWidth
            label="Title"
            onChange={handleTitleChange}
            value={title}
            sx={{ margin: '10px', padding: '10px' }}
            variant="standard"
          />
          <div style={{ width: '100%', overflow: 'auto' }}>
            <textarea
              rows={20}
              style={{ width: '100%', overflow: 'scroll', whiteSpace: "nowrap", resize: 'none', border: '1px solid #ccc', padding: '8px' }}
              value={markdownText}
              onChange={handleMarkdownChange}
            />
          </div>
          <span>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
          </span>
          <Button variant="contained" onClick={save}>Save</Button>
          <Button variant="outlined" onClick={previewMarkdown}>Preview</Button>
        </Paper>
        {/* <MarkdownPreview renderedMarkdown={renderedMarkdown} title="Preview" /> */}
        <MarkdownPreview renderedMarkdown={markdownText} title="Preview" />
      </Container >
    </div>
  )
}

export default Editor
