import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import MarkdownPreview from '../components/markdown/Preview'
import Button from '@mui/material/Button'
import { getArticleDetail } from '../api/blog'

const Preview = () => {
  const [article, setArticle] = useState({})
  const routeParams = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    getArticleDetail(routeParams.id)
      .then(json => setArticle(json))
  }, [routeParams.id])

  const editArticle = () => {
    navigate(`/editor/${routeParams.id}`, { title: article.article, content: article.content })
  }
  return (
    <>
      <Button variant="outlined" sx={{ margin: '5px', float: 'right' }} onClick={editArticle}>Edit</Button>
      <MarkdownPreview renderedMarkdown={article.content} />
    </>
  )
}


export default Preview