import React from 'react'
import { Paper, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'

const MarkdownPreview = (props) => {
  return (
    <Paper maxwidth='100vw' elevation={3} sx={{ flex: 1, padding: 3, marginLeft: 2, overflowX: 'auto', height: '100vh' }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {props.title}
      </Typography>
      <div style={{ maxHeight: 'calc(100vh - 48px)', overflow: 'auto', overflowX: 'auto' }}>
        <ReactMarkdown>{props.renderedMarkdown}</ReactMarkdown>
      </div>
    </Paper>
  )
}

export default MarkdownPreview