import { Routes, Route } from 'react-router-dom'
import NavBar from './views/NavBar'
import Login from './views/Login'
import Home from './views/Home'
import Editor from './views/Editor'
import Preview from './views/Preview'
import avatar from './assets/avatar.png'

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <NavBar logo={avatar}>
            <Home />
          </NavBar>
        }
      />
      <Route
        path="/home"
        element={
          <NavBar logo={avatar}>
            <Home />
          </NavBar>
        }
      />
      <Route
        path="/editor/:id"
        element={
          <NavBar logo={avatar}>
            <Editor />
          </NavBar>
        }
      />
      <Route
        path="/editor"
        element={
          <NavBar logo={avatar}>
            <Editor />
          </NavBar>
        }
      />
      <Route
        path="/preview/:id"
        element={
          <NavBar logo={avatar}>
            <Preview />
          </NavBar>
        }
      />
    </Routes >
  )
}

export default App
