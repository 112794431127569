import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'


export default function BlogCard(props) {
  const navigate = useNavigate()
  const username = 'username'
  const updatedAt = '2020-10-11'

  const previewArticle = () => {
    if (props.id) navigate(`/preview/${props.id}`)
    else navigate('/editor')
  }

  return (
    <div className="col-md-4 my-3">
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="rpcipe" src={props.avatar}></Avatar>
          }
          title={username}
          subheader={updatedAt}
        />
        <CardMedia
          component="img"
          height="194"
          image={props.logo}
          alt="Paella dish"
          onClick={previewArticle}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {props.title}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}